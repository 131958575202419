/* eslint-disable react/display-name */
import React from 'react'
import Figure from './Figure'
import { Typography } from '@material-ui/core'
import { Link } from 'gatsby-theme-material-ui'

export const treatmentSerializers = {
  types: {
    block: (props: any) => {
      switch (props.node.style) {
        case 'h1':
          return <Typography variant={'h1'}>{props.children}</Typography>

        case 'h2':
          return <Typography variant={'h2'}>{props.children}</Typography>

        case 'h3':
          return <Typography variant={'h3'}>{props.children}</Typography>

        case 'h4':
          return <Typography variant={'h4'}>{props.children}</Typography>
        case 'blockquote':
          return (
            <Typography
              variant={'body1'}
              style={{ fontStyle: 'italic', color: '#9ca2ac' }}
            >
              {props.children}
            </Typography>
          )
        default:
          return (
            <Typography
              variant={'body1'}
              className={props.className || undefined}
              gutterBottom
              style={{ fontSize: '1rem' }}
            >
              {props.children}
            </Typography>
          )
      }
    },
    figure: (props) => {
      return <Figure {...props.node} />
    },
  },
  list: (props: any) => {
    return (
      <Typography
        variant="body1"
        component={props.types === 'bullet' ? 'ol' : 'ul'}
        style={{
          paddingLeft: `2rem`,
          paddingTop: `1rem`,
          paddingBottom: `1rem`,
        }}
      >
        {props.children}
      </Typography>
    )
  },
  listItem: (props: any) => {
    return <li>{props.children}</li>
  },
  marks: {
    link: (props: any) => {
      return (
        <Link to={props.mark.href} color="primary" rel={'nofollow noopener'}>
          {props.children}
        </Link>
      )
    },
  },
}
