import { createMuiTheme, PaletteType } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'
import { themeContext } from '../Context/ThemeContext'
import { Palette } from '@material-ui/core/styles/createPalette'

const defaultFam = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
].join(',')
export const typography = {
  fontFamily: defaultFam,
  body1: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    lineHeight: '1.6',
    fontWeight: 400,
    fontSize: '1.3rem',
  },
  body2: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  h1: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    color: '#FFFFFE',
    fontWeight: 600,
  },
  h2: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: 600,
    color: '#FFFFFE',
  },
  h3: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: 600,
    color: '#FFFFFE',
  },
  h4: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: 600,
    color: '#FFFFFE',
  },
  h5: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: 600,
    color: '#FFFFFE',
  },
  h6: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeight: 600,
    color: '#FFFFFE',
  },
}
export const overrides: Overrides = {
  MuiButton: {
    root: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      position: 'relative',

      '&> *': {
        position: 'relative',
      },
      '&::before': {
        pointerEvents: 'none',
        content: '""',
        borderRadius: 'inherit',
        backgroundColor: '#0074E0',
        opacity: 0,
        transition: 'opacity .2s',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      '&:hover::before, &:focus::before': {
        opacity: 1,
      },
    },
    containedSecondary: {
      background: `linear-gradient(270deg, #0074E0 0%, #3083CF 39.06%, #309FCF 96.88%);`,
      color: `#000`,
    },
    outlinedSecondary: {
      '&:before': {
        background: 'transparent',
      },
      '&:hover': {
        background: `linear-gradient(270deg, #0074E0 0%, #3083CF 39.06%, #309FCF 96.88%);`,
        color: 'black',
      },
    },
  },
  MuiPaper: {
    elevation0: {
      background: '#242629',
    },
  },
  MuiLink: {
    root: {
      color: '#fefefe',
      '&:hover, &:focus': {
        background: `linear-gradient(270deg, #0074E0 0%, #3083CF 39.06%, #309FCF 96.88%);`,
        WebkitTextFillColor: 'transparent',
        WebkitBoxDecorationBreak: 'clone',
        WebkitBackgroundClip: 'text',
        textDecorationLine: 'none',
      },
    },
  },
}
//#232528 BG COLOR
export const palette: Palette = {
  type: 'dark' as PaletteType,
  primary: {
    main: '#0D0D0D',
  },
  secondary: {
    main: '#309FCF',
  },
  text: {
    primary: `#FEFEFE`,
    secondary: `#309FCF`,
  },
  background: {
    paper: '#0d0d0d',
    default: '#232528',
  },
}

export const treatmentsTheme = createMuiTheme({
  palette,
  typography,
  overrides,
})
