import React from 'react'
import Layout from '../../components/layout'
import {
  Container,
  Box,
  Grid,
  Typography,
  Hidden,
  Button,
  Theme,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
} from '@material-ui/core'
import { graphql } from 'gatsby'

import SEO from '../../components/SEO'
import { HelmetProvider } from 'react-helmet-async'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import BlockContent from '../../components/block-content'
import Img, { FluidObject } from 'gatsby-image'

import { Link } from 'gatsby-theme-material-ui'
import clsx from 'clsx'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import Scrollspy from 'react-scrollspy'
import { TreatmentsPageQuery } from '../../../@types/graphql-types'
import { Tags } from '../../components/Tags'
import useHideOnScroll from '../../hooks/hideOnScroll'
import { treatmentSerializers } from '../../components/block-content/treatments'
import { treatmentsTheme } from '../../theme/treatments'
import { MakeAppointment } from '../../components/MakeAppointment'

interface TreatmentsPageProps {
  data: TreatmentsPageQuery
}
const useStyles = makeStyles((theme: Theme) => ({
  backgroundColor: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '-220%',
    right: theme.spacing(2),
    zIndex: 0,
    backgroundColor: theme.palette.background.default,
  },
  treatmentContainer: {
    margin: theme.spacing(0, 0, 12, 0),
    '&:last-of-type': {
      marginBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
  },

  allTreatments: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: `calc(3.5rem - 64px)`,
    },
  },
  sideBar: {
    zIndex: 1,
    listStyle: 'none',
    padding: 0,
    // display: 'flex',
    position: 'fixed',
    width: '100%',
    '& li:not(:first-child)': {
      marginTop: theme.spacing(0.25),
    },
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(12),
      maxWidth: '200px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '215px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '280px',
    },
  },
  isCurrent: {
    '& > a': {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  padded: {
    padding: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 0, 0, 1.5),
    },
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      maxHeight: '250px',
    },
  },
  description: () => ({
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
      '& > div > p': {
        lineClamp: 'none',
        boxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),

  hideFullTreatment: {
    [theme.breakpoints.down('xs')]: {
      '& > div > p:not(:first-child)': {
        display: 'none',
      },
    },
  },
  showFullTreatment: {
    [theme.breakpoints.down('xs')]: {
      '& > * > *': {
        display: 'block',
      },
    },
  },
  backToTopBtn: {
    opacity: 0,
    pointerEvents: 'none',
    zIndex: 99,
    transition: 'opacity .2s',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderRadius: '50%',
    },
  },
  showBackToTopBtn: {
    [theme.breakpoints.down('xs')]: {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
}))

const TreatmentsPage: React.FC<TreatmentsPageProps> = (props) => {
  const description = React.useRef(null)
  const classes = useStyles(props)
  const [showFullTreatment, setShowFullTreatment] = React.useState(
    Array(props.data.allSanityTreatment.edges.length).fill(false)
  )

  const [, isScrolledTop] = useHideOnScroll()

  const toggleReadmore = (index: number) => {
    const newArr = [...showFullTreatment]
    newArr[index] = !newArr[index]
    setShowFullTreatment(newArr)
  }

  return (
    <HelmetProvider>
      <SEO title="Behandelingen" />
      {/* <Meta /> */}
      <ThemeProvider theme={treatmentsTheme}>
        <Layout>
          <Container maxWidth="lg">
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={8}
                md={9}
                lg={9}
                className={classes.allTreatments}
              >
                {props.data.allSanityTreatment.edges.map((edge, index) => (
                  <Card
                    key={index}
                    className={classes.treatmentContainer}
                    id={edge.node.slug.current}
                  >
                    {edge.node.background && (
                      <CardMedia>
                        <Img
                          className={classes.image}
                          fluid={
                            edge.node.background.asset.fluid as FluidObject
                          }
                        />
                      </CardMedia>
                    )}
                    <CardContent>
                      <Box className={classes.padded} component={'article'}>
                        <Typography variant={'h4'} gutterBottom>
                          {edge.node.title}
                        </Typography>
                        <div ref={description}>
                          <Box
                            component={'div'}
                            className={clsx(
                              classes.description,
                              showFullTreatment[index]
                                ? classes.showFullTreatment
                                : classes.hideFullTreatment
                            )}
                          >
                            <BlockContent
                              blocks={edge.node._rawBody}
                              serializers={treatmentSerializers}
                            />
                          </Box>
                        </div>
                        <Hidden smDown>
                          <Box py={3}>
                            <MakeAppointment size={'medium'} url={edge.node.appointmentUrl} />
                          </Box>
                        </Hidden>
                        <Hidden smUp>
                          {!showFullTreatment[index] && (
                            <Box py={2} display={'flex'}>
                              <Button
                                variant="outlined"
                                color="secondary"
                                style={{ marginRight: '1rem' }}
                                onClick={() => toggleReadmore(index)}
                              >
                                Lees meer
                              </Button>
                              <MakeAppointment size={'small'} url={edge.node.appointmentUrl} />
                            </Box>
                          )}
                        </Hidden>
                        <Tags tags={edge.node.people} />
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  sm={4}
                  md={3}
                  lg={3}
                  style={{ zIndex: 1, position: 'relative' }}
                  component={'aside'}
                >
                  {/* <div className={classes.backgroundColor} /> */}
                  <Scrollspy
                    items={props.data.allSanityTreatment.edges.map((item) => {
                      return `${item.node.slug.current}`
                    })}
                    componentTag={List}
                    currentClassName={classes.isCurrent}
                    className={classes.sideBar}
                  >
                    {props.data.allSanityTreatment.edges.map((edge, key) => {
                      return (
                        <ListItem key={key} component={'li'}>
                          <Link
                            underline="none"
                            variant="h6"
                            href={`#${edge.node.slug.current}`}
                          >
                            {edge.node.title}
                          </Link>
                        </ListItem>
                      )
                    })}
                  </Scrollspy>
                </Grid>
              </Hidden>
            </Grid>
            <div
              className={`${classes.backToTopBtn} ${
                isScrolledTop ? classes.showBackToTopBtn : ''
              }`}
            >
              <IconButton
                aria-label="back to top"
                color="secondary"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <ArrowUpwardIcon fontSize="large" />
              </IconButton>
            </div>
          </Container>
        </Layout>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export const pageQuery = graphql`
  query TreatmentsPage {
    allSanityTreatment(sort: { fields: order, order: ASC }) {
      edges {
        node {
          title
          slug {
            current
          }
          people {
            fullName
            slug {
              current
            }
            avatar {
              asset {
                fixed(width: 25) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
          background {
            asset {
              fluid(maxWidth: 930) {
                ...GatsbySanityImageFluid
              }
            }
          }
          _rawBody
          appointmentUrl
        }
      }
    }
  }
`
export default TreatmentsPage
